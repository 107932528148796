export const AirCarbon = 'https://storage.googleapis.com/scooter-scout/scooters/AirCarbon.jpg';
export const AirAluminum = 'https://storage.googleapis.com/scooter-scout/scooters/AirAluminum.png';
export const Dualtron3 = 'https://storage.googleapis.com/scooter-scout/scooters/Dualtron3.png';
export const DualtronSpider = 'https://storage.googleapis.com/scooter-scout/scooters/DualtronSpider.jpg';
export const DualtronThunder = 'https://storage.googleapis.com/scooter-scout/scooters/DualtronThunder.png';
export const EcoRecoL5 = 'https://storage.googleapis.com/scooter-scout/scooters/EcoRecoL5.png';
export const EcoRecoL5Plus = 'https://storage.googleapis.com/scooter-scout/scooters/EcoRecoL5Plus.png';
export const EcoRecoM5 = 'https://storage.googleapis.com/scooter-scout/scooters/EcoRecoM5.jpg';
export const EcoRecoS5 = 'https://storage.googleapis.com/scooter-scout/scooters/EcoRecoS5.jpg';
export const EmoveTouring = 'https://storage.googleapis.com/scooter-scout/scooters/EmoveTouring.jpg';
export const GlionDolly = 'https://storage.googleapis.com/scooter-scout/scooters/GlionDolly.jpg';
export const GoPedESR750 = 'https://storage.googleapis.com/scooter-scout/scooters/GoPedESR750.jpg';
export const GotraxG1GliderFolding = 'https://storage.googleapis.com/scooter-scout/scooters/GotraxG1GliderFolding.jpg';
export const GotraxG2ElectricKick = 'https://storage.googleapis.com/scooter-scout/scooters/GotraxG2ElectricKick.jpg';
export const GoTraxGXLCommuter = 'https://storage.googleapis.com/scooter-scout/scooters/GoTraxGXLCommuter.jpg';
export const GotraxGXLCommuterV2 = 'https://storage.googleapis.com/scooter-scout/scooters/GotraxGXLCommuterV2.jpg';
export const ImaxS1Plus = 'https://storage.googleapis.com/scooter-scout/scooters/GotraxGXLCommuterV2.jpg';
export const MegawheelsS1 = 'https://storage.googleapis.com/scooter-scout/scooters/MegawheelsS1.jpg';
export const NinebotES1 = 'https://storage.googleapis.com/scooter-scout/scooters/NinebotES1.jpg';
export const QiewaQ1Hummer = 'https://storage.googleapis.com/scooter-scout/scooters/QiewaQ1Hummer.jpg';
export const QiewaQMini = 'https://storage.googleapis.com/scooter-scout/scooters/QiewaQMini.jpg';
export const RazorE300 = 'https://storage.googleapis.com/scooter-scout/scooters/RazorE300.jpg';
export const RazorEcoSmartMetro = 'https://storage.googleapis.com/scooter-scout/scooters/RazorEcoSmartMetro.jpg';
export const RazorEPrime = 'https://storage.googleapis.com/scooter-scout/scooters/RazorEPrime.png';
export const SegwayNinebotES2 = 'https://storage.googleapis.com/scooter-scout/scooters/SegwayNinebotES2.jpg';
export const SegwayNinebotES4 = 'https://storage.googleapis.com/scooter-scout/scooters/SegwayNinebotES4.jpg';
export const SpeedwayMini4Pro = 'https://storage.googleapis.com/scooter-scout/scooters/SpeedwayMini4Pro.png';
export const SwagtronSwagger = 'https://storage.googleapis.com/scooter-scout/scooters/SwagtronSwagger.jpg';
export const SwagtronSwagger5CityCommuter = 'https://storage.googleapis.com/scooter-scout/scooters/SwagtronSwagger5CityCommuter.jpeg';
export const UberScoot300W = 'https://storage.googleapis.com/scooter-scout/scooters/UberScoot300W.jpg';
export const UberScoot1000W = 'https://storage.googleapis.com/scooter-scout/scooters/UberScoot1000W.jpg';
export const UberScoot1600W = 'https://storage.googleapis.com/scooter-scout/scooters/UberScoot1600W.jpg';
export const UscootersBoosterPlusS = 'https://storage.googleapis.com/scooter-scout/scooters/UscootersBoosterPlusS.jpg';
export const UscootersEco = 'https://storage.googleapis.com/scooter-scout/scooters/UscootersEco.jpg';
export const VoyagerIon = 'https://storage.googleapis.com/scooter-scout/scooters/VoyagerIon.jpg';
export const VoyagerProton = 'https://storage.googleapis.com/scooter-scout/scooters/VoyagerProton.jpg';
export const XiaomiMi365 = 'https://storage.googleapis.com/scooter-scout/scooters/XiaomiMi365.jpg';
export const XPRIT65 = 'https://storage.googleapis.com/scooter-scout/scooters/XPRIT65.jpg';
export const ZoomStryderEx = 'https://storage.googleapis.com/scooter-scout/scooters/ZoomStryderEx.jpg';
import styled from 'styled-components'

export const FilterContainer = styled.div`
    border: 1px solid limegreen;
    border-radius: 5px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-top: 20px;
`

import {
AirCarbon,
AirAluminum,
Dualtron3,
DualtronSpider,
DualtronThunder,
EcoRecoL5,
EcoRecoL5Plus,
EcoRecoM5,
EcoRecoS5,
EmoveTouring,
GlionDolly,
GoPedESR750,
GotraxG1GliderFolding,
GotraxG2ElectricKick,
GoTraxGXLCommuter,
GotraxGXLCommuterV2,
ImaxS1Plus,
MegawheelsS1,
NinebotES1,
QiewaQ1Hummer,
QiewaQMini,
RazorE300,
RazorEcoSmartMetro,
RazorEPrime,
SegwayNinebotES2,
SegwayNinebotES4,
SpeedwayMini4Pro,
SwagtronSwagger,
SwagtronSwagger5CityCommuter,
UberScoot300W,
UberScoot1000W,
UberScoot1600W,
UscootersBoosterPlusS,
UscootersEco,
VoyagerIon,
VoyagerProton,
XiaomiMi365,
XPRIT65,
ZoomStryderEx,
} from './images/scooters/googleCloudStorage'

export const scooters =
[
  {
    "Unofficial rank": "high",
    "make": "Emove",
    "model": "Touring",
    "image": EmoveTouring,
    "otherBuyLink": "https://www.voromotors.com/products/emove-touring",
    "description": "The Emove Touring has great acceleration and suspension, making it ideal for bumpy city roads.",
    "negative1": "My suspensions squeak like crazy (hopefully some WD40 will fix this?).",
    "positive1": "The scooter is a joy to ride. I was initially shocked by the acceleration of the Touring, compared to my ES2. However, the adjustment period was relatively quick and I got to fully experience what the scooter had to offer. The scooter is adept at conquering shitty NYC roads thanks to its suspension and pneumatic front tire. Because of this, I feel a lot safer riding the Touring than my ES2. ",
    "price": 910,
    "speed": 24,
    "range": 37,
    "weight": 30,
    "lights": "Both"
  },
  {
    "Unofficial rank": "high",
    "make": "Magnum bikes",
    "model": "Imax S1+",
    "image": ImaxS1Plus,
    "otherBuyLink": "https://www.magnumbikes.com/product/imax-s1-electric-scooter/",
    "description": "A powerful scooter with good battery life. Beware of tire issues.",
    "negative1": "In the 1 year I have been using the scooter to commute during the week, I have had 4 flat tires.",
    "positive1": "While it's not as much fun as snowboarding powder, that's what it reminds me of. Only no lift ticket and you end up at the grocery store, work, home, or somewhere practical that you have to be anyway",
    "positive2": "Scooter rides very comfortable, the integrated bell and brakes are excellent - overall really enjoying it.",
    "positive3": "The motor is very powerful and almost silent it is so quiet and the larger air tube tires are very stable. I ride this thing everywhere and it rolls great on the road, sidewalk, and even through grass with no problem! The battery life is amazing and I enjoy riding at night with the built in taillight and added headlight. I",
    "price": 1299,
    "speed": 20,
    "range": 20,
    "weight": 36,
    "lights": "Both",
    "outsideReview": "electricridereview.com"
  },
  {
    "Unofficial rank": "high",
    "make": "Glion",
    "model": "Dolly",
    "image": GlionDolly,
    "amazonLink": "https://amzn.to/2G4yqzc",
    "otherBuyLink": "https://glion-scooter.com/dolly/",
    "description": "The Glion Dolly stands out for its portability. It folds up and then can be rolled like a suitcase (or dolly, hence the name), making it perfect for multi-modal scooter commuters to take on a bus or a train.",
    "negative1": "I think it\\'s an excellent value for someone who\\'s looking to travel a couple of miles every day and wants a quick commute.",
    "positive1": "Here\\'s where the Glion shines - the scooter stands vertically by itself. this is a huge plus if there is only standing room on the train. the short position on the handle is perfect to hold the scooter in place but also rest some weight on it.",
    "positive2": "The battery takes 3 hours or less to fully charge. This is a big deal cuz some scooters take 8-12 hours to charge.'",
    "positive3": "I keep up with most cyclists, and am even faster than a small handful.",
    "price": 499,
    "speed": 16,
    "range": 16,
    "weight": 28,
    "lights": "None"
  },
  {
    "Unofficial rank": "high",
    "make": "Razor",
    "model": "EcoSmart Metro",
    "image": RazorEcoSmartMetro,
    "amazonLink": "https://amzn.to/2DgIT8a",
    "description": "The Razor EcoSmart Metro is a big comfortable scooter with a seat, and a basket for carrying your things. But the batteries and brakes leave something to be desired.",
    "negative1": "The batteries will not last. I've had mine for about 18 months. I use it for a 4 mile round trip several times a week and I'm on my third set of batteries.",
    "negative2": "The brake is pretty sad. It's a drum brake, rear wheel only, and it hardly stops me at all.",
    "negative3": "The seat is fairly comfortable compared to most bicycle seats, but without any shocks, it does get a bit hard when riding for a while.",
    "positive1": "The scooter comes with a basket that I use to carry a lock, groceries, workout clothes, the charger, etc.,",
    "positive2": "I love the big flat deck for my feet.",
    "positive3": "It gets a lot of attention. Can't tell you how many people have stopped to comment on it. It's as good as walking a dog for getting to know your neighbors.",
    "price": 379,
    "speed": 18,
    "range": 12,
    "weight": 67,
    "lights": "None",
    "outsideReview": "envyride.com"
  },
  {
    "Unofficial rank": "high",
    "make": "Xiaomi",
    "model": "Mi M365",
    "image": XiaomiMi365,
    "amazonLink": "https://amzn.to/2WyhYMH",
    "description": "The Xiaomi Mi M365 are the scooters used by Bird, and they're a popular consumer model for a reason. They're a good speed and range for the price, with solid braking. Plus, the big community of owners means it's easy to get troubleshooting advice should you ever encounter an issue.",
    "negative1": " There is no suspension. You will feel every bump and crack in the road/sidewalk. When there's one that I can't avoid, I usually squat a little bit and let my legs absorb the hit. This works well over speed bumps and mild curbs too. For full size curbs just hop off and walk it up.",
    "negative3": "In all modes climbing hills is difficult, small grades are ok especially if you are light. This is where your body weight makes a huge difference. I weigh of 200 lbs so hills are slow going and kill the battery.",
    "negative4": "I got the app working ONCE on my Pixel 2 phone: turned on Mi Home app, click (+) on the app to add a new device, turned on scooter, and it connected! I was finally able to turn on Cruise Control which helped a ton when you're doing a long commute on a clear street / bike lane. After that I couldn't get it to connect again but at least I turned on the one feature I wanted.",
    "positive1": "I’ve put over 150 miles on the scooter in Los Angeles, Santa Monica, and Venice Beach, including Pasadena, Burbank and Hollywood. Scooter is fast, powerful, smooth and reliable. I’ve ridden the scooter well over the 18 mile range several times",
    "positive2": "Range is pretty amazing for $499, but on all-day excursions over 15 miles, the charger just fits in my shorts pocket, allowing recharges in the corner of a brewery patio or park. Combining this with Houston's light rail system, or throwing it in the back of an Uber, opens up the whole city.",
    "positive3": "Braking is great. The front wheel uses some type of regenerative braking that charges the battery, the back disk brake adds kinda a trail braking effect.",
    "positive4": "I want to point out another good thing about this scooter is the large community behind it. You can google M365 Forum or help and you can get answers to most of the questions you have and links to purchase replacement parts. I tried to do research on a few competing scooters in the price range and came up short when looking for replacement parts / troubleshooting.\n",
    "price": 450,
    "speed": 16,
    "range": 19,
    "weight": 27,
    "lights": "Both",
    "outsideReview": "electric-scooter.guide"
  },
  {
    "Unofficial rank": "high",
    "make": "Dualtron",
    "model": "III",
    "image": Dualtron3,
    "otherBuyLink": "https://minimotorsusa.com/collections/frontpage/products/dualtron-iii",
    "description": "Dualtron is to Bird and Lime scooters as a four-door-sedan is to a racecar. That is, this is one premium scooter! Dualtrons are powerful and can make it up hills with ease. The model 3 has a giant footboard so it's easy to stand on.",
    "price": 2969,
    "speed": 40,
    "range": 75,
    "weight": 80,
    "lights": "None",
    "Extras: American-Made, Financing, Seat, Custom Paint, All Terrain, Fun Colors": "Affirm plans",
    "outsideReview": "https://www.youtube.com/watch?v=ZyOTEdp9Zto"
  },
  {
    "Unofficial rank": "high",
    "make": "Dualtron",
    "model": "Spider",
    "image": DualtronSpider,
    "otherBuyLink": "https://minimotorsusa.com/collections/frontpage/products/dualtron-spider-electric-scooter",
    "description": "The Dualtron Spider is the Unicorn of scooters: it's speedy, powerful AND lightweight. That's a combo you can't find in almost any other scooter on the market.",
    "negative2": "There are two marginal LED headlamps on the front and barely visible brake lights in the rear. They barely do anything, and buying external lighting is mandatory. ",
    "positive1": "You are not getting this kind of performance on ANY other scooter in the 40-45 lb weight class",
    "positive2": "The brakes offer PLENTY of stopping power. They are predictable and easily modulated.",
    "positive3": "the general build quality of the scooter is fantastic. The entire frame feels extremely solid and strong",
    "positive4": "It is insanely torquey and fun to ride. There are several modes / ways to set the power level of the scooter. First - you can decide whether to use single or dual motors. Second - you can pick Eco versus Turbo mode. Lastly, there are 3 general power levels you can choose from.",
    "price": 2450,
    "speed": 37,
    "range": 37,
    "weight": 44,
    "lights": "Both",
    "outsideReview": "https://www.reddit.com/r/ElectricScooters/comments/abfhe9/dualtron_spider_indepth_review/"
  },
  {
    "Unofficial rank": "high",
    "make": "Dualtron",
    "model": "Thunder",
    "image": DualtronThunder,
    "otherBuyLink": "https://minimotorsusa.com/collections/frontpage/products/dualtron-thunder",
    "description": "Dualtron is to Bird and Lime scooters as a four-door-sedan is to a racecar. That is, this is one premium scooter! And this is the best model Dualtron offers; and arguably, the best scooter money can buy.",
    "positive1": "the DualTron Thunder is one of those scooters that are as fast and as heavy as a small motorcycle or moped. Unlike the latter, the Thunder can be easily stored in your bedroom. It does not have that peculiar gasoline smell a motorcycle has, plus it can be folded and kept literally under your bed.",
    "positive2": "The brakes are super! Acceleration is orbital!!! Heavier than the previous generation, what is fine since-90 km/h it takes weight for sticking to the road! ",
    "price": 3799,
    "speed": 50,
    "range": 75,
    "weight": 95,
    "lights": "Both",
    "outsideReview": "https://www.youtube.com/watch?v=uqt7DKfCTVo"
  },
  {
    "Unofficial rank": "high",
    "make": "Speedway",
    "model": "Mini 4 Pro",
    "image": SpeedwayMini4Pro,
    "otherBuyLink": "https://minimotorsusa.com/collections/speedway/products/speedway-mini-4-pro",
    "description": "The Speedway Mini 4 Pro is fast but stable, and has strong headlights to make riding at night safe.",
    "negative1": "Scooter was going well 2 months, and after that scooter start switching off from no reason any time which is seriously dangerous.",
    "negative2": "The brake could be improved.",
    "positive1": "It has two headlights that cast a beam up to 25 feet in front of you, you’ll feel very comfortable riding this thing at night time.",
    "positive2": "Amazing scooter, really powerful and sturdy. Ideal for a daily commute.",
    "positive3": "Very stable and good speed.",
    "price": 1150,
    "speed": 28,
    "range": 30,
    "weight": 35,
    "lights": "Both"
  },
  {
    "Unofficial rank": "high",
    "make": "Qiewa",
    "model": "Q1 Hummer",
    "image": QiewaQ1Hummer,
    "amazonLink": "https://amzn.to/2Cump1F",
    "description": "The Qiewa Q1 Hummer is powerful but smooth. This hearty scooter can survive wear and tear, and even go off-roading. This is not your childhood scooter.",
    "negative1": "If you ride after dark, you will want to mount a blinking light on the rear fender...the tail lights only light up when you use the brakes.",
    "negative2": "Only minor complaint is that when folded sometimes the horn button gets pushed and scares me.",
    "negative3": "Changing flat tire is very difficult to get tire on/off rim. Damaged motor wires in process.",
    "positive1": "This is NOT a \"last mile solution\" scooter. If that is what you are looking for, there are plenty of lighter and cheaper options out there. This is perfect for someone that is looking for a rig that is tough enough, fast enough and has the endurance for every day travel. I use mine for traveling to my various fly fishing destinations as well as running errands. It is on the heavier side, but manageable.",
    "positive2": "It's super stable, quiet, and easy to ride, while it also appears very durable.",
    "positive3": "The full suspension is awesome (must have for me) and having TWO disc brakes was essential as I've gotten over 40mph downhill. Not having two disc brake imo would be really unsafe, cuz this thing hauls azz.",
    "positive4": "I am 275 pounds yet it carried me quite easily and was very smooth.",
    "positive5": "I can't say enough good things about their customer service",
    "price": 1399,
    "speed": 34,
    "range": 62,
    "weight": 55,
    "lights": "Both",
    "Extras: American-Made, Financing, Seat, Custom Paint, All Terrain, Fun Colors": "All Terrain",
    "outsideReview": "envyride.com"
  },
  {
    "Unofficial rank": "high",
    "make": "Segway",
    "model": "Ninebot ES4",
    "image": SegwayNinebotES4,
    "amazonLink": "https://amzn.to/2DOELws",
    "description": "The Segway Ninebot ES4 is a good option for the price. It's a thrill to ride, and it can handle daily use. Best of all, the many lights on this scooter will keep you safe at night.",
    "negative1": "It wasn't until I turned on the headlight as it started getting dark one evening that I knew something was wrong. The power dropped to hardly nothing. This was still after I had 5 bars on my power level meter. As soon as I turned the headlight off, all was fine again. This only happened after the scooter had a bit more power required to go up a hill. I previously had the headlight turned on and it was running fine as long I was riding on a flat surface. Now, the power drops even on flat surfaces. I did some investigating on this issue and I found it is a widespread and a very common problem.",
    "negative2": "After a small crash (not my fault) on my scooter. Brake throttle is messed up and scooter will not operate. Been on the phone back and forth with factory and repair services. Seems like no one wants to or even had the means to fix these",
    "negative3": "As mentioned earlier, the scooter is a little on the heavier side. It's not meant to be carried around over longer distances, but isn't so heavy that you can't lift it to move it around when needed. When I take mine on the bus, for example, I have no problem folding it up to stow away either beneath the bus or in the overhead compartment if there's space. The wall charger is light enough to bring around, though with the additional battery attached, it lasts my daily commute, and I just plug it into the wall when I get home.",
    "negative4": "The scooter didn't even last a month (less than 40 miles total) before it broke apart. It was great for about 3 weeks then the rear fender snapped off meaning no rear brake and no way to fold the scooter. To make matters even worse the front hub somehow cracked, several small rubber washers kept popping off and it was becoming completely unsafe to ride so I returned it. ",
    "positive1": "The headlights, bottom lights, and rear lights make it clearly visible at night, which is a huge plus.",
    "positive2": "It's a thrill to ride and has a solid construction, making it feel safe to ride.",
    "positive3": "The ES4 is built using solid materials, and it feels like it can take a reasonable bit of beating. The tires are thick and balanced, and the body is made with thick metal. It has a bit of weight to it, especially with the external battery attached, but in this case I consider it a sign of its durability. Though clearly not your off-road vehicle, it's great for urban/suburban transportation.",
    "positive4": "The app works flawlessly. You can customize many options from speed to cruise control to a full selection of under glow lighting.",
    "price": 769,
    "speed": 19,
    "range": 28,
    "weight": 31,
    "lights": "Both",
    "outsideReview": "electric.travel"
  },
  {
    "Unofficial rank": "high",
    "make": "Qiewa",
    "model": "Qmini",
    "image": QiewaQMini,
    "amazonLink": "https://amzn.to/2MfkW3K",
    "description": "The Qiewa Q1 Mini is a well-built scooter that's plenty fast, and has a long range. In the unlikely event that you encounter an issue, Qiewa is a responsive and reliable company.",
    "negative1": "The brakes are adjustable, but be aware - they're not good enough, they are weak. This is extremely big minus of this scooter.",
    "positive1": "This also has solid tires--another must-have feature for me. (This can make the ride less smooth, but the dual-shocks compensate well here). For the uninitiated, changing pneumatic tubes & tires around the scooter's hub motor can be a real pain. It can be difficult and it's easy to cause damage to the scooter tire/wheel or yourself in the process if you do it improperly. I got plenty of experience changing them with my last scooter and honestly didn't want to be bothered anymore. ",
    "positive2": "The Qmini has handlebars that can be adjusted to a custom heights. (Many others have can adjust to just one or two discrete heights.) ",
    "positive3": "The final key thing like (and not to be underestimated) is good/responsive customer service and the ability to get replacement parts and/or help when needed.",
    "price": 1080,
    "speed": 30,
    "range": 37,
    "weight": 48,
    "lights": "Both",
    "outsideReview": "gearpriest.com"
  },
  {
    "Unofficial rank": "high",
    "make": "Evo Powerboards",
    "model": "Uber Scoot 300W (ES04)",
    "image": UberScoot300W,
    "amazonLink": "https://amzn.to/2GoM6oV",
    "description": "The UberScoot 300W has thick tires that give the scooterer a sense of sturdiness. It's quiet, and the cutouts in the body make it easy to lock up. ",
    "negative1": "Somewhat low torque. This means slower uphills, can manage just fine though.",
    "negative2": "The rear wheel alignment is somewhat difficult to adjust if the chain needs to be tightened. Also, the rear wheel cannot be inflated unless you have a special tire inflater or the rear wheel must be taken off the scooter to add air.",
    "positive1": "This product is sturdy, easy to use, everything you would expect from a middle-tier electric scooter. I would say it competes with scooters that are upwards of $500!",
    "positive2": "Large tires give you confidence to go over larger bumps (which it can handle)",
    "positive3": "Easy to lock up though cutouts in metal body. Easy to roll around (but it's heavy, so it's hard to carry long distances).",
    "positive4": "Extremely quiet to navigate.",
    "price": 270,
    "speed": 11,
    "range": 9,
    "weight": 40,
    "lights": "None"
  },
  {
    "Unofficial rank": "high",
    "make": "Evo Powerboards",
    "model": "Uber Scoot 1000W (ES06)",
    "image": UberScoot1000W,
    "amazonLink": "https://amzn.to/2Ghz77m",
    "description": "The UberScoot 1000W is sturdy and speedy, and can handle hills and off-roading. It even comes with a key, which reduces the chance of it being stolen.",
    "negative1": "It is heavy and awkward to carry. I am 5’11 and fit. I would not want to carry it up any stairs. ",
    "negative2": "The down side is it's heavy to carry in and out of the back seat of my truck.",
    "negative3": "My only complaint is that it tends to be a little squeaky, (especially over bumps). I've oiled everything, but I guess that's just how it's supposed to be/sound.",
    "positive1": "Great scooter, definetly worth the money, I’ve ridden for over an hour at top speed and battery still read full. Top speed is roughly 16mph, and 22mph when the button turbo is on. Very easy to assemble, feels extremely sturdy when riding, accelerates quickly. ",
    "positive2": "The uberscoot is the best! I've rode it 15 plus miles and reach speeds of 35mph, it doesn't slow down on hills , sturdy I highly recommend this scooter. ",
    "positive3": "This scooter is very strong and engineered to last. Lots of torque, speed and power. Excellent disc brakes. The unit requires a key which reduces my concerned for the unit getting stolen. If your in the market for a good quality scooter, you can end your search because you have found it. I would highly recommend this unit for young or old.",
    "positive4": "My daughter takes it up and down hills, across grass and dirt w/ no problems. ",
    "positive5": "I’m 5’10” and 250lbs and this thing moves me around without a problem.",
    "price": 620,
    "speed": 17,
    "range": 11,
    "weight": 66,
    "lights": "Both"
  },
  {
    "Unofficial rank": "high",
    "make": "Evo Powerboards",
    "model": "Uber Scoot 1600W",
    "image": UberScoot1600W,
    "amazonLink": "https://amzn.to/2WIeVl8",
    "description": "The UberScoot 1600W is a fast, durable scooter with offroading capabilities.",
    "negative1": "The received item needs a lot of tweaking. The front wheel is rubbing against the brake pads. Have to realign the caliper to minimize the friction. So as the rear wheel. I have to realign the rear wheel to achieve proper chain tension. I have to adjust or loosen some bolts to allow the shocks to have some play for both front and rear. It is a pain to adjust the bolts that holds the rear shock absorber. To gain access, you have remove the wheel, lower bolts that holds the shocks, and motor. There are no documentation or specifications of this scooter parts. ",
    "negative2": "The seat needs to be more comfortable.",
    "positive1": "This scooter is very powerful, long-lasting, and extremely fun, especially given the reasonable price. I highly recommend it for adults wanting to have a good time!!! It is so much better than cheaper brands like Razor.",
    "positive2": "This is the best electric scooter in this size that we have ever owned. The Customer service from this company is great.",
    "positive3": "Love my UberScoot 1600 It is strong and fast - great for any adult. The tires are great for any terrain, curbs, etc! Acceleration and braking is nice and smooth giving the rider a lot of confidence in the handling.",
    "positive4": "IT IS FAST!",
    "price": 885,
    "speed": 20,
    "range": 12,
    "weight": 117,
    "lights": "Both"
  },
  {
    "Unofficial rank": "high",
    "make": "UScooter",
    "model": "Eco",
    "image": UscootersEco,
    "amazonLink": "https://www.amazon.com/twow-USA-USCOOTERS-ELECTRIC-SCOOTER/dp/B06X9FZJ59",
    "otherBuyLink": "https://uscooters.com/products/uscooter-eco",
    "description": "The UScooter Eco is smooth and lightweight. The hand brakes are a little abrupt and take some getting used to, but there is also a foot brake.",
    "negative1": "After 3 months, the front wheel/motor/break started to vibrate or wiggle. We contacted the company, all they did was sent replacement parts with instructions. We are not experts and could possibly damage it more. That did not resolve the issue. Now 6 months later, the gas button broke.",
    "negative2": "The brakes can be a bit abrupt in the beginning when you are not used to scooting around. You just need to get used to the brake lever being sensitive. If you want a smoother brake you can use the back foot brake, where you press your foot down on the back fender.",
    "negative3": "The brakes stop abruptly.",
    "positive1": "It is by far the best electric scooter I've ever owned and checks all the boxes on my wish list plus more on everything I want in an electric scooter. Such as, its a compact, lightweight 23 lbs., yet sturdy and solid. There's a non skid platform which I love because it isn't too long like some scooters, large airless tires that never go flat, front and rear suspension, shock absorption meaning I glide over cracks & bumps on the road, front and rear tail light and very good speed, and a nice display that shows you the milage, battery percentage, speed, temperature, and distance. ",
    "positive2": "I have had my uscooter eco for 6 months now (i.e., 2018 model), and have done about 600 miles on it! I use it to commute to work in the Boston area, and the roads aren't exactly smooth riding here, and the scooter has given me no problems whatsoever.",
    "positive3": "Another fun thing is this scooter doesnt use a kickstand but instead you step on the folding mechanism to put it in a kneeling sort of camel position. Another cool factor is it has a cool horn to warn other riders to move out if the way.",
    "price": 650,
    "speed": 16,
    "range": 16,
    "weight": 24,
    "lights": "Both"
  },
  {
    "Unofficial rank": "high",
    "make": "UScooter",
    "model": "Booster Plus S+",
    "image": UscootersBoosterPlusS,
    "amazonLink": "https://amzn.to/2Ia1lnm",
    "description": "The UScooter Booster Plus S+ boasts an impressive power to weight ratio. It's light, it's sleek -- and it's doggone speedy.",
    "negative1": "After a few months of light using (just go the 11 blocks to work and back on flat road and maybe the gym) the back wheel started whobbling.",
    "positive1": "Best scooter on the market for so many reasons. We own two and have had them for over 3 years. One is booster + I have done zero maintenance to them and have put 2000+ miles on them. My fiancé loves his and I can actually carry this one in the subway if needed since it’s only 23 pounds. It’s the lightest, fastest and most durable scooter on the market. ",
    "positive2": "This scooter changed my commute! Fantastic product. I get to pass a few bikes on my way to work",
    "positive3": "Range is impressive. Enough power for a 220 lbs person.\nBest part is the ease of folding and taking it inside with me.\nLiterally looks like a kick scooter that deliver better than those big bulky and heavy scooter.\nNot to mention a pretty comfortable ride with the suspension.",
    "price": 1049,
    "speed": 22,
    "range": 20,
    "weight": 24,
    "lights": "Both"
  },
  {
    "Unofficial rank": "high",
    "make": "Xprit",
    "model": "6.5″",
    "image": XPRIT65,
    "amazonLink": "https://amzn.to/2UTJoe4",
    "description": "The Xpirit 6.5\" is an amazing deal. It performs well in all-weather and has headlights for nighttime visibility. You will feel bumps in the road, and you should be very careful with the brakes -- but this little scooter outperforms its pricepoint.",
    "negative1": "It is a little bit heavier than I thought, i have to use both of my hands to carry it. I’m 130 lb woman btw.",
    "negative2": "The brake is literally 100% on or off. At the half way point of the lever, all of a sudden it kicks on and lurches. There’s just no way to brake slightly, but it also sucks pretty bad at braking. It’s a motor brake, NOT a mechanical one, so on a full battery, it’s pretty darn powerful (which happens all at once whether you like it or not) and at any lower charge, it’s just dangerous. Several times I was moving at maybe half speed, hit the brake, and had to jump off to avoid rolling right into the street. It just sucks. So you either get way too much braking or not enough on a sliding scale during the day. Terrible.",
    "negative3": "This scooter is not as nice as the Bird or Lime scooters by far, but it's also only a fraction of the cost compared to those higher end models. I don't think having shocks should cost an extra $300, so I'll stick with this scooter for now.",
    "negative4": "Brakes are kinda slow to stop completely",
    "positive1": "I must say, for the price of 200 dollars, this product is hella solid. It performs well in rain, on dust, mud, and gravel, and in both warm and cold weather. With this beast, I can get to my math class that normally takes 25 minutes to walk to in just under 8 minutes.",
    "positive2": "The wheels grip well, even on wet roads.",
    "positive3": "The headlight is bright and well-angled to illuminate the road ahead when going fast at night.",
    "positive4": "NO tire tube means no flat tires or BS!",
    "price": 180,
    "speed": 12,
    "range": 13,
    "weight": 23,
    "lights": "Front",
    "outsideReview": "envyride.com"
  },
  {
    "Unofficial rank": "high",
    "make": "Zoom",
    "model": "Stryder EX",
    "image": ZoomStryderEx,
    "otherBuyLink": "https://www.ridezoom.co/shop/zoom-stryder/",
    "description": "The Zoom Stryder Ex is a great city commuter scooter. Fast, can handle hills,and the battery seems to last forever.",
    "negative1": "Throttle and brake are a little touchy. It's not a deal breaker but isn't not exactly a smooth, analog feel. Almost a digital on/off feeling. You can ease it on smoothly but it takes a lot of precision and finesse to do it.",
    "negative2": "Handles packed, flat dirt trails quite well but it does suffer once you hit more rocky or silty terrain. The lower grip street tires and powerful motor make it easy to spin out. ",
    "negative3": "Brake / back fender rattles a little on bump terrain. I might try to find something to sure that up and reduce vibration. Only a minor annoyance.",
    "negative4": "Update: customer service is completely silent after responding with a total of 2 incomplete sentences; no resolve, no communication. We had to call the credit card company to reverse the charge/dispute the transaction. I would not recommend; very bad experience. Warranty is not honored - it's a bad deal if you happen to have a problem.",
    "positive1": "Lots of torque and super zippy. Powers up hills no problem.",
    "positive2": " Battery lasts seemingly forever.",
    "positive3": "The suspension works perfectly over cracked roads and small bumps.",
    "positive4": "The regenerative brakes are much better than I thought they would be. They stop you fast. There is no need to use the rear mud guard brake at all. ",
    "price": 800,
    "speed": 19,
    "range": 22,
    "weight": 24,
    "lights": "Both"
  },
  {
    "Unofficial rank": "medium",
    "make": "EcoReco",
    "model": "L5+",
    "image": EcoRecoL5Plus,
    "otherBuyLink": "https://ecorecoscooter.com/product/l5/",
    "description": "The EcoReco L5+ is a fun torque-y vehicle, but there is a learning curve. Not for scooterers who just want a smooth ride.",
    "negative1": "Unfortunately, after 100+ miles, it started squeaking and creaking pretty loud, mostly around the shaft and shock absorbers. It was from the very beginning but became worse over time. Besides that, I have to check and tighten bolts and nuts once or twice per week, this really annoying.\n\nAnd today I found my self on halfway from home to work with a broken bearing in the front wheel.",
    "negative2": "I have the L5 Plus also and I am having issues in that the speed and distance recorded on the instrument cluster is not correct.",
    "negative3": "It has more torque than my other 4 scooters that I have to compare it too. the brakes, as I mentioned before are effective but a bit gnarly to get used too. I broke my wrist about 6 months back due to the fact that I wasn't used to the hard tug of the brakes at that speed and took a nice tumble. Learned my lesson and moved past it.",
    "positive2": "I honestly love the L5+ despite the learning curve.",
    "price": 1099,
    "speed": 23,
    "range": 32,
    "weight": 39,
    "lights": "Rear",
    "outsideReview": "https://www.youtube.com/watch?v=BRrVVREPna4&t="
  },
  {
    "Unofficial rank": "medium",
    "make": "EcoReco",
    "model": "M5",
    "image": EcoRecoM5,
    "amazonLink": "https://amzn.to/2WCDXBQ",
    "description": "The EcoReco M5 is sturdy, fast, and portable.",
    "negative1": "As a warning to future buyers, their customer service is terrible. ",
    "positive1": "I brought an EcoReco back in December and absolutely love it. It's lightweight and easy to carry.",
    "positive2": "This scooter hits the sweet spot of sturdiness, reliability, speed, weight and portability.\n\n I've done 20 degree hills while speeding by people on their bikes. I weigh 180lbs for reference.",
    "positive3": "This scooter goes stupid fast, and can go up hills no problems. Yeah, it can be unsafe but only if you're an idiot about it. Overall a very well-engineered product and exactly what you'd want in an electric scooter.",
    "price": 999,
    "speed": 20,
    "range": 20,
    "weight": 36,
    "lights": "Rear",
    "Extras: American-Made, Financing, Seat, Custom Paint, All Terrain, Fun Colors": "LCD display with speedometer, battery charge, distance traveled",
    "outsideReview": "electricridereview.com"
  },
  {
    "Unofficial rank": "medium",
    "make": "EcoReco",
    "model": "S5",
    "image": EcoRecoS5,
    "otherBuyLink": "https://ecorecoscooter.com/product/s5/",
    "description": "The EcoReco S5 is light and zippy! But it could use better brakes and more torque (for getting up hills).",
    "positive1": "Perfect for city errands (with a back pack) because it easily folds up small and light enough to carry like a suitcase into stores; fits in bottom of a shopping cart or in a gym locker. Zippy enough to surf the streets on, just for the fun of it :-) Very happy with first few outings. Also like that owner/ user can do self maintenance and repairs.",
    "positive2": "This scooter is built like a tank. It’s able to get me to work (3 miles on a paved trail) oe 20-40% of a charge going 18-22mph the whole time. That’s pretty reasonable given I’m over 200lbs and the 20 mile range is rated around 12-15mph. I would appreciate a little more torque to help get up hills, but I don’t mind to help it out with a kick every now and then. Overall well worth the price",
    "positive3": "I weigh 200 and it moves me around great . Can use a head light and better breaks . But overall GREAT",
    "price": 699,
    "speed": 20,
    "range": 20,
    "weight": 28,
    "lights": "Rear"
  },
  {
    "Unofficial rank": "medium",
    "make": "Go-Ped",
    "model": "ESR750",
    "image": GoPedESR750,
    "otherBuyLink": "http://goped.com/esr750-electric-scooter/",
    "description": "The Goped ESR750 is fast and durable, with the tradeoff that it's heavy and you have to exercise care when lifting it.",
    "negative1": "It is heavy and bulky. When lifting this, watch your back!",
    "negative2": "The Goped doesn't have any suspension-- Because the 750 goes SO fast you'll notice the bumps in the pavement at first. ",
    "positive1": "Extra wide wooden deck provides ample space for people with big feet",
    "positive2": "Yes, this scooter is heavy. And yeah, it's expensive. But it's worth it. \n\nThe front disc brake is incredibly responsive. I've already had to perform a few \"emergency stops,\" and the brake worked just fine. I weigh 220 lbs, and this scooter doesn't seem to be straining to move or stop me, which I like.\n",
    "positive3": "Simple and easy to change a tire, batteries and brake pads and they have videos on how to do all this. I ride it to and from work daily and I ride it hard.",
    "positive4": "The Goped 750 is pretty llight for what you're getting.  \n\nIt is well made and durable, solid wood deck, solid metal frame, and the optional seat attachment is solid metal also but not excessively heavy. Goped is known for being a higher end scooter and is light enough and \"compactable\" enough to pick up without hurting your back.",
    "price": 1750,
    "speed": 12,
    "range": 22,
    "weight": 47,
    "lights": "None",
    "Extras: American-Made, Financing, Seat, Custom Paint, All Terrain, Fun Colors": "Custom color",
    "outsideReview": "electricridereview.com"
  },
  {
    "Unofficial rank": "medium",
    "make": "MegaWheels",
    "model": "S1",
    "image": MegawheelsS1,
    "amazonLink": "https://amzn.to/2SZLsAV",
    "description": "This scooter is not daily commuters. But it might be a fun novelty item or make a great gift for a kid.",
    "negative1": "This scooter malfunctioned in less than 24 hours. It wouldn't move when the hand throttle was depressed. It couldn't be turned off or shut down",
    "negative2": "Thinks of this as an \"e-assist\" scooter, in that you will still need to kick a bit, and it won't go up urban hills.",
    "negative3": "I would not ride it on the streets since it doesn't quite have enough power. ",
    "negative4": "I like it for a play toy if u want to use it for you might want to get something a little more Durable.",
    "price": 199,
    "speed": 14,
    "range": 5,
    "weight": 15,
    "lights": "Rear"
  },
  {
    "Unofficial rank": "medium",
    "make": "Razor",
    "model": "E300",
    "image": RazorE300,
    "amazonLink": "https://amzn.to/2GDawu1",
    "description": "The Razor E300 is more of a toy than a vehicle. Buy it for a bit of fun on the weekends, but don't plan to use it on your daily commute.",
    "negative1": "So far I've noticed it does not like hills which isn't a surprise. Since I want to go far I will usually get off and push it up because I want to save battery.",
    "negative2": "Since purchasing it 4 months ago, the power has declined to a point where it could no longer be ridden after 2 months.",
    "negative3": "This scooter is not foldable + super heavy, so this does not even fit in to my SUV's cargo room.",
    "negative4": "However, you immediately notice that the BATTERY LIFE DOES NOT LAST. I started off charging the scooter every other night before class, and now I have to bring the charger with me at all times to charge the scooter whenever possible because it simply will not sustain battery life for longer than 2 hours",
    "positive1": "I love the larger platform because it gives more standing room and makes the ride more comfortable.",
    "positive2": "They are super easy to ride, and plenty fast. Battery life is solid for what I use it for, I am around 180 pounds and it has no trouble pulling me when I ride it. All in all a pretty solid product, cant beat it especially if you commute short distances",
    "positive3": "I'm 160 lbs, I live in Boulder and use this to get to and from classes. The motor is quiet enough that I haven't been bothered by any authorities on campus. It makes it up the hills in Boulder just fine. All I hear while riding it are comments like \"I need one of those\". ",
    "positive4": "It can even go over grass for short distances, particularly if you've already got a good head of steam going.",
    "price": 248,
    "speed": 15,
    "range": 10,
    "weight": 43,
    "lights": "None",
    "outsideReview": "envyride.com"
  },
  {
    "Unofficial rank": "medium",
    "make": "Razor",
    "model": "E Prime",
    "image": RazorEPrime,
    "otherBuyLink": "https://www.razor.com/products/electric-scooters/eprime-electric-scooter/",
    "description": "The Razor E Prime is sleek and zippy, but not durable. There are better scooters in this price range.",
    "negative1": "this scooter has hard plastic wheels and no suspension. So, every single vibration every single granule of gravel on a paved street shakes the entire scooter and every bone in your body.",
    "negative2": "Not only are the wheels hard plastic, but they are skinny. This is a huge disadvantage compared to competing electric scooters, it can easily get stuck in train and trolley tracks as well as storm grates.\n",
    "negative3": "1st scooter would not turn off - then would not turn on - after 4 days use. Returned it and bought second. The power switch panel screw came off after 2 days.",
    "positive1": "It is quiet, sturdy, and the speed is impressive. Plus, the design is sleek.",
    "positive2": "This one has some real \"zip\" and looks fantastic. Cool design, lithium-ion power, and an awesome battery charge meter to make sure you don't run out of juice before your next charge. ",
    "price": 379,
    "speed": 15,
    "range": 10,
    "weight": 22,
    "lights": "None",
    "outsideReview": "geekspin.co"
  },
  {
    "Unofficial rank": "medium",
    "make": "Segway",
    "model": "Ninebot ES2",
    "image": SegwayNinebotES2,
    "amazonLink": "https://amzn.to/2t0TIoQ",
    "description": "The Segway Ninebot ES2 is a good option for the price. It's fun to ride, has sexy colored lights along the baseboard, and even has an app. However, the scooter can struggle on hills, and the speed is sometimes inconsistent.",
    "negative1": "Riding experience was not as good as I expected. My weight is around 200lb + I am a tall guy. So the scooter loses speed or even stops while going uphill, even with relatively slight inclines. ",
    "negative2": "The regular usage of my scooter over the time I’ve had it has caused some marks and damage to appear on it that is my only other minor complaint. On the rear brake there is a rubber crescent that was meant to protect the stem from the hard plastic of the brake itself. That fell off after about a week of use, and seemed to only be lightly stuck to the back with some adhesive rubbery substance. Now there is a black smudge on the silver bar because every time it folds, it rubs. Other similar marks have appeared over time that have no effect on the usage of the scooter, just the aesthetic.",
    "negative3": "So I have had this scooter for a little more than 4 months and it's frustrating. I purchased the external battery and man did the power increase. However, it's too inconsistent. One day you can be cruising at 19 mph, then the next day at 11mph and don't know why. Scooter is fully charged and in sport mode so I'm not sure why the mph has become such an issue.",
    "positive1": "There is an app for the ninebot which helps you change color of the lights under the board of the scooter,records an accumulation of your speed, and a couple of other features.",
    "positive2": "Was able to handle some small hills of the Denver area this no issue. Construction seems solid and the ride is fairly smooth.",
    "positive3": "I use this scooter on a daily basis, replacing my bike to go up to a mile to college. It works well, goes fast, and is a really fun experience.",
    "positive4": "I LOVE LOVE LOVE LOVE this scooter. One of the best purchases I have made. Well built. Runs quiet. Lacks just a bit on the brakes, but nothing to worry about. I ride this on a daily Basis in Denver. I get stopped every day on the street by strangers wondering where I got this scooter and can they get one. ",
    "price": 569,
    "speed": 16,
    "range": 16,
    "weight": 28,
    "lights": "Both",
    "outsideReview": "envyride.com"
  },
  {
    "Unofficial rank": "medium",
    "make": "Swagtron ",
    "model": "Swagger",
    "image": SwagtronSwagger,
    "otherBuyLink": "https://www.amazon.com/SWAGTRON-Swagger-Electric-Ultra-Lightweight-Fold-n-Carry/dp/B01LDPT3IK/ref=sr_1_1_sspa?crid=3U4MMJ134PTSJ&keywords=swagtron+swagger&qid=1552203593&s=gateway&sprefix=swagtron+swagger%2Caps%2C224&sr=8-1-spons&psc=1",
    "description": "Lightweight scooter with headlights for night driving. Beware, you will feel every bump and crack in the road.",
    "negative1": "Cons: barely any suspension, you will feel cracks and bumps on uneven surfaces.",
    "negative2": "The scooter is sturdy enough to riding over street bumpers, road cracks, but be careful coming up on the street potholes, which can cause your scooter to throw you off, like it did me on two occasions.",
    "negative3": "The handbrake requires getting some used to. It's too responsive and I initially kept using the rear fender brake because I felt like the sudden handbraking was going to launch me off the scooter especially at higher speed",
    "negative4": "I had used this scooter on and off for 1-mile commutes to work each way. After countless times of sending this thing in, I've never had a successful repair performed by the company. It is infuriating to send this thing back (which is a huge pain due to its size as well as the long processing time) only to receive the unit back with the same issue unresolved and/or new issues over the span of a YEAR.",
    "positive1": "This scooter is very light with a carbon-fiber frame. I weighed it at just above 17 lbs, and I have no problem lifting it up the stairs. I feel like I'm in control of the scooter, not the other way around",
    "positive2": "Love it! Pros: light, sexy, clean, sturdy. Fast. Charges quickly. Bright headlights for night driving. Can't beat the price. ",
    "positive3": "Swagtron manufacturer has a US office based in Indiana. There's actually a customer support line that I can call and speak to live English speaking CSRs. I gave up ordering a few other similar scooters with more reviews because I couldn't get a hold of a live contact and I was concerned for possible warranty shipping offshore.",
    "positive4": "After experiencing issues with the motor about 6 months in, I contacted Swagtron support. They were very helpful, sent me a prepaid shipping label. I got my scooter back in less than two weeks, they fixed the motor and now it feels like a new scooter. Very impressed with the service.",
    "price": 280,
    "speed": 15,
    "range": 15,
    "weight": 15
  },
  {
    "Unofficial rank": "medium",
    "make": "Voyager",
    "model": "Ion",
    "image": VoyagerIon,
    "amazonLink": "https://amzn.to/2I9os1o",
    "description": "The Voyager Ion is sturdy and affordable, making it a good option for a student wanting to ride around campus. However, it won't survive most work commutes because the range is much less than advertised.",
    "negative1": "It says max range is 10 miles. So I tested it with a full charge, only the 3rd time I rode it, and rode it till it was dead. It only went 4.5 miles. I am just under 220 lbs, and 6ft tall.",
    "negative2": "Battery for my 6 mile commune leaves it fully dead 10 miles was promise. I carry the charger with me but it’s still a hassle to charge it when I was told 10.",
    "negative3": "I bought this scooter to go over to a friend's house which is a mile away. I am an adult male and weigh 200 lb. I had charged it overnight and had five bars when I left my house, by the time I got to my friends house which is all level ground I was at three bars of power. On my way back I went from three bars down to one in about half the distance and end up pushing myself back to get back home. I don't know where they get their ratings of how far that unit will travel but it's definitely not for adults.",
    "negative4": "Do be aware that you can attain pretty impressive speeds and the hand brake alone may not suffice for a quick stop. In that case, either utilize the foot brake also or simply jump off.",
    "positive1": "Scooter is very compact and light, and sturdy, which for me are its best qualities.",
    "positive2": "Great for first time buyer with less than 5 miles round trip. ",
    "positive3": "I've had the bird scooter and the lime scooter in the past both were okay with battery and distance but this one trumps them all with cruise control, extremely bright leds, the option to slide start and get this gears... Yes it has 3 gears with different power levels.. This is by far my favorite scooter of the 3!",
    "positive4": "If I wanted a $300 scooter I would have bought a $300 scooter; for the price this one is amazing. It goes fast enough that I'm not worried about getting to class; it's not as fast as my bike, but by the time my bike is all locked up, I could already be inside with my scooter, so it makes for the same difference. The battery hasn't amazed me, but it's enough to get anywhere I need to when spending 12 hours on campus, with only about 45 minutes of midday charging. This scooter feels sturdy and grips well even in rain and over snowy roads.",
    "price": 190,
    "speed": 12,
    "range": 10,
    "weight": 19,
    "lights": "Front",
    "outsideReview": "r/DaveAndBusters"
  },
  {
    "Unofficial rank": "medium",
    "make": "Voyager",
    "model": "Proton",
    "image": VoyagerProton,
    "amazonLink": "https://amzn.to/2TGVmaO",
    "description": "The Voyager Proton is an inexpensive and lightweight scooter with extra lights for staying visible and safe at night. However, it's not very powerful, and the range is less than advertised. Good for children and small adults with short, flat commutes.",
    "negative1": "I am about 185lbs and for me it goes 11MPH max instead of 15MPH as advertised. I also did not like the wheels. It's much different than Bird or Lime bikes in that the wheels are not rubber inflated but instead, much smaller and hard. So you'll feel the road very rough.",
    "negative2": "My only significant complaint is the range. The best total range is near 4 miles, less than half the advertised range.",
    "negative3": "This is a great scooter for how much it costs. But do not expect it to be as good quality as the more expensive ones. I am about 165 pounds and the max it would go on level ground was about 13 mph. If you are going downhill it will go fast because of gravity, but performance is severely capped when going uphill. The scooter is definitely geared more for people who are lightweight, I'd say under 110 pounds. If you think of it as a fun toy you will enjoy it a lot...do not consider it to be a commuting vehicle.",
    "negative4": "The Voyager Proton is a small, lightweight electric scooter that barely qualifies for use by a normal-sized adult. The wheels are very small and hard, so you feel every bump and you can't go over anything that's over an inch in height (including the lowest speed bumps). Then there's the range. Two miles is what you can reliably count on, but forget about ever going over four before the batteries die. And that's at any speed, too. Going slower doesn't extend the range.\n\nSo, if you don't have far to go and the surface is completely flat and smooth with zero incline, it's okay for the price. Since it's so small and light, it's easier than a real adult scooter to carry around. Otherwise, pony up the extra dough and get a Xaomi M365 (i.e., a Bird scooter).",
    "positive1": "This Scooter performs great with less than 150lbs of weight and light hills! It's fairly light and gets up to about 15mph. The front shock is decent, though the tires aren't going to provide any grace.\nThis is a great entry scooter for a light adult just looking to putt around.",
    "positive2": "Folds up and easy to carry. Digital display is cool and super easy to ride. The lights on the front and back are pretty bright which we like. ",
    "positive3": "People don’t realize how incredible it is to have something so small and light take you so far. For me at around 200lbs it takes me reliably about 5 miles or so which is plenty as I charge back up at work. If you know what you’re getting into you won’t have complaints. The only con I have is the torque. My old scooter would almost go up a wall with its motor and chain drive. This scooter being motor-in-wheel has very little torque. Expect to give a push each start or you’ll murder your battery very quickly! That said it gets going nicley up to speed. Hills are a bit of a struggle though.",
    "positive4": " The Dual lights makes this the coolest looking scooter around - Super bright LED on top and long strip of Dark Blue going down the bar. You gotta see it to appreciate it, especially at night. ",
    "price": 245,
    "speed": 15,
    "range": 10,
    "weight": 19,
    "lights": "Front"
  },
  {
    "Unofficial rank": "low",
    "make": "EcoReco",
    "model": "L5",
    "image": EcoRecoL5,
    "otherBuyLink": "https://ecorecoscooter.com/product/l5/",
    "description": "The EcoReco L5 brakes too suddenly, gets twitchy at high speeds, and has issues with the tires.",
    "negative1": "I found it always engaged at a higher level of brake force than I would prefer. I like to more gradually brake. ",
    "negative2": "The tires on the L5 are flat, more like car tires in their surface profile than bike/motorcycle tires. As such they were happy when riding straight at lower speed, but as soon as you pass 12-15mph they become too twitchy for my liking.",
    "negative3": "I have had my L5 for a while now. Maybe 1300 miles. Front wheel came off. I felt a wobble and then it came off. I was soing about 15+ mph. Took a spill ripped my jacket and gloves. Thankfully I was protected.\n\nNot happy with the wheel breaking so easily. For the amount of money you pay. Should be sturdier, better made.",
    "positive1": "I love my L5! Going on 500 miles and not a lick of trouble. I commute on it daily and take it everywhere, inside grocery stores, restaurants, coffee shops, light rail and buses, etc. etc. No one ever hassles me. Even after many months of daily use, it's almost impossible to ride it and not smile.",
    "positive2": "The full suspension does a great job at smoothing out the bumps.\n",
    "positive3": "I have an L5 with 1500 miles on it. Nothing to report except the takedown lever being stiff during takedown sometimes. I take it 9.2 miles to work and back everyday. I've had this scooter for almost a year now.",
    "price": 999,
    "speed": 23,
    "range": 14,
    "weight": 38,
    "lights": "Rear"
  },
  {
    "Unofficial rank": "low",
    "make": "So Flow",
    "model": "Air Carbon",
    "image": AirCarbon,
    "otherBuyLink": "https://so-flow.us/products/electric-scooter",
    "description": "So Flow is an affordable brand but nothing to write home about. Beware the customer service.",
    "negative2": "So Flow stopped replying to me when I asked for a refund months ago. I’ve tried to contact them a few times since and get nothing.",
    "price": 449,
    "speed": 15,
    "range": 13,
    "weight": 15,
    "lights": "Both"
  },
  {
    "Unofficial rank": "low",
    "make": "So Flow",
    "model": "Air Aluminum",
    "image": AirAluminum,
    "otherBuyLink": "https://so-flow.us/products/flowboard-air-aluminum",
    "description": "So Flow is an affordable brand but nothing to write home about. Beware the customer service.",
    "negative2": "So Flow stopped replying to me when I asked for a refund months ago. I’ve tried to contact them a few times since and get nothing.",
    "price": 349,
    "speed": 15,
    "range": 10,
    "weight": 17,
    "lights": "Both",
    "outsideReview": "https://www.youtube.com/watch?v=980d1YA-dk4"
  },
  {
    "Unofficial rank": "low",
    "make": "Gotrax",
    "model": "GXL Commuter",
    "image": GoTraxGXLCommuter,
    "amazonLink": "https://amzn.to/2GgpshC",
    "description": "Gotrax scooters are affordable but you get what you pay for. Be prepared for this scooter to stop working all of a sudden. Best for people who just want to try out a scooter and don't plan to commute with it.",
    "negative1": "Well, I put it together as directed and rode it down my condo hallway to test it out and it suddenly made an awful grinding noise, then stopped working altogether. I",
    "negative2": "I am a 115-lb female and this scooter was broken within the first week. Total motor failure.",
    "negative3": "I noticed a strange rattling sound coming from the front wheel. It is very loud and embarrassing when going down the street.",
    "negative4": "Air filled tires are an awesome feature. Having ridden on hard rubber wheel scooters, I'll say this makes for a much better ride. With that said, my front tire got a puncture in the first few days, and will say getting the tire back on once tube is repaired/replaced is a nightmare. It literally took three tire wedges and four hands to slip the tire back on.",
    "price": 299,
    "speed": 16,
    "range": 12,
    "weight": 31,
    "lights": "Front"
  },
  {
    "Unofficial rank": "low",
    "make": "Gotrax",
    "model": "G1 Glider Folding",
    "image": GotraxG1GliderFolding,
    "amazonLink": "https://www.amazon.com/GOTRAX-Glider-Electric-Scooter-Adults/dp/B078BRRYB5",
    "description": "Gotrax scooters are affordable but you get what you pay for. Be prepared for this scooter to stop working suddenly. Best for people who just want to try out a scooter and don't plan to commute with it.",
    "negative1": "So this scooter worked great for about two months and is already broken. I don't know what happened as the thing fully charges but just won't go anymore.",
    "price": 200,
    "speed": 16,
    "range": 9,
    "weight": 20,
    "lights": "Front",
    "outsideReview": "gearpriest.com"
  },
  {
    "Unofficial rank": "low",
    "make": "Gotrax",
    "model": "G2 Electric Kick",
    "image": GotraxG2ElectricKick,
    "amazonLink": "https://www.amazon.com/GOTRAX-G2-Electric-Scooter-Commuter/dp/B07DLJBTSN",
    "otherBuyLink": "https://gotrax.com/products/g2-electric-kick-scooter#a_aid=escooterguide",
    "description": "Gotrax scooters are affordable but you get what you pay for. Be prepared for this scooter to stop working suddenly. Best for people who just want to try out a scooter and don't plan to commute with it.",
    "negative1": "Extremely disappointed. Got this for my husband’s birthday. It wouldn’t charge. Wouldn’t run, none of the instructions on the users’ manual is true.",
    "price": 229,
    "speed": 15,
    "range": 7,
    "weight": 24,
    "lights": "Front"
  },
  {
    "Unofficial rank": "low",
    "make": "Gotrax",
    "model": "GXL Commuter v2",
    "image": GotraxGXLCommuterV2,
    "otherBuyLink": "https://gotrax.com/products/gxl-commuter-scooter-hand-brake-edition#a_aid=escooterguide",
    "description": "Gotrax scooters are affordable but you get what you pay for. Be prepared for this scooter to stop working suddenly. Best for people who just want to try out a scooter and don't plan to commute with it.",
    "negative1": "One thing I immediately noticed is how hard the tires it comes with are. They feel like a rock, even completely deflated.",
    "price": 350,
    "speed": 16,
    "range": 12,
    "weight": 31,
    "lights": "Front"
  },
  {
    "Unofficial rank": "low",
    "make": "Segway",
    "model": "Ninebot ES1",
    "image": NinebotES1,
    "amazonLink": "https://amzn.to/2WA4EHt",
    "description": "The Segway Ninebot ES1 is fun but not well-made, and seems to frequently have mishaps. It's hard to find a strictly positive review... This scooter is okay for light use but expect it to break after a few months of heavy use.",
    "negative1": "Today the screws that hold the plastic hook to keep the scooter locked while folded in half cane loose. So not I can’t carry it folded. After about 6 weeks and 80 miles, build quality is becoming a serious issue.",
    "negative2": "It’s heavy. There’s no way to just pull it behind you. If it had some smaller wheels like another scooter I have which would allow it to balance itself while it’s being pulled along.",
    "negative3": "Only thing I don’t like is the handle bar plastic is cheap, and the footpad started to come off Where you stand, but easily fixed.",
    "negative4": "The App is necessary for initial use but after that not needed. Its highly intrusive in terms of permissions so turning a few off is\nprobably a good idea. Not sure why they need or want to scrape my contacts etc....\n",
    "positive1": "The scooter is very solid and made of great materials. The wheels are durable and it feels very secure while riding.",
    "positive2": "The front headlight is nice and bright. ",
    "positive3": "The charger is also pretty small so it's easy to take with you and charge on the go (pending you have an outlet).",
    "price": 550,
    "speed": 12,
    "range": 16,
    "weight": 25,
    "lights": "Front",
    "outsideReview": "envyride.com"
  },
  {
    "Unofficial rank": "low",
    "make": "Swagtron",
    "model": "Swagger 5 City Commuter",
    "image": SwagtronSwagger5CityCommuter,
    "amazonLink": "https://www.amazon.com/SWAGTRON-Commuter-Electric-Cushioned-Controlled/dp/B07F45HL7M/ref=sr_1_3?crid=3FL1OLK9PD2AE&keywords=swagtron+city+commuter+electric+scooter&qid=1552204912&s=gateway&sprefix=swagtron+city+commute%2Caps%2C200&sr=8-3",
    "otherBuyLink": "https://swagtron.com/product/swagtron-swagger-5-folding-electric-scooter/#a_aid=5c58bb800487f",
    "description": "This is an affordable scooter for a short commute, but the range and speed do not live up to the brand's claims, and seem to decline over time. ",
    "negative1": "Trying to stick to facts as much as I can here. I weigh 190lbs and my daily commute with this product is mostly flat ground. On a flat surface I can get up to 16mph on the first half of the battery charge. The speed drops to about 13mph on the second half of the battery charge. I am only able to average about 4 miles per charge before the scooter battery starts to die mid-ride requiring me to turn the scooter off and back on again to get another quarter of a mile of range. \n\nIn two months, I am already beginning to see deterioration in the above speed and range. I have no doubt that after averaging 30-35 miles per week over the next 6 months, I will be unable to get 3 miles or total range on a charge which will make the unit useless",
    "negative2": "The mile range never got to 12 miles max, no matter the weather, ride condition it just would up to 8 miles range and battery would die( on flat surface all the time and I weight 140 lb)\nAlso the 3rd speed level would work for few minutes and then only would go down to 2nd speed even having battery in full. (Disappointed)",
    "negative3": "I bought my 7 year old son a razor 100 electric scooter at the same time as this purchase with the thought we would ride together. I was dismayed when the speed only matched the kids scooter rated at 10 mph. This was on the highest setting 3, level smooth surface, 220 rider, and wind to my back! Specs say 18mph, up to 320 weight",
    "negative4": "The app is a horrible version 1, with no upgradability have like the xaoxmi 365. It disconnected from Bluetooth for no reason while attached to the handlebars with Velcro. Useless app.",
    "positive1": "For anyone that is just starting, on a budget , and doesn't intend to ride too far, then this might be the one you're looking for. I've had a blast with this device. ",
    "positive2": "The app works well enough, I like the adjustable speed limit. I use this when I am running my dog with the scooter, I keep it on gear 1 or 2 when doing that.",
    "positive3": "Overall, I've been happy with this purchase. The scooter has been (mostly) reliable with getting me to class a mile+ away on time every day. You just have to be really careful about the conditions you ride the scooter in and where you ride it, also what you're riding on or over.",
    "positive4": "As compared to the other commercial scooters I've rented, the Swagger 5 Elite was very comparable. It was just as fast (if not a little faster). The Swagger five feels substantial and well built. The electronics were essentially the same as the other commercial scooters - with speed, battery, and bluetooth connectivity readings. The battery was more than enough to get me around and was able to maintain close to top speed until say less than 20% of the charge remained.",
    "price": 350,
    "speed": 18,
    "range": 11,
    "weight": 28,
    "lights": "Both",
    "outsideReview": "envyride.com"
  }
]
